import {
  Club,
  Field,
  Game,
  News,
  Organization,
  Round,
  Team,
  Tournament
} from 'types'
import { germanRoutes } from 'routes'
// import querystring from 'querystring'
import { apiBaseUrl, siteBaseUrl } from 'api/helpers'
import { getValidFilters } from './filterHelpers'
import queryString from 'query-string'
// import { getValidFilters } from './filterHelpers'

export const nonValidUrl = '#'

const daySchedulePathName = germanRoutes.daySchedule
const leaguesPathName = germanRoutes.leagues
const tournamentsPathName = germanRoutes.tournaments
const tablePathName = germanRoutes.table
const statisticsPathName = germanRoutes.statistics
const schedulePathName = germanRoutes.schedule
const scheduleSearchPathName = germanRoutes.scheduleSearch
const teamLeagueSchedulePathName = germanRoutes.teamLeagueSchedule
const allGamesPathName = germanRoutes.allGames
const roundsPathName = germanRoutes.rounds
const gamesPathName = germanRoutes.games
const livePathName = germanRoutes.live
const clubsPathName = germanRoutes.clubs
const teamsPathName = germanRoutes.teams
const newsPathName = germanRoutes.news
const newsletterPathName = germanRoutes.newsletter
const squadPathName = germanRoutes.squad
const organizationsPathName = germanRoutes.organizations
const imprintPathName = germanRoutes.imprint
const nutritionBlogPathName = germanRoutes.nutritionBlog
const healthBlogPathName = germanRoutes.healthBlog
const contactPathName = germanRoutes.contact
const termsOfUsePathname = germanRoutes.termsOfUse
const faqPathName = germanRoutes.faq
const fieldsPathName = germanRoutes.fields
const searchPathName = germanRoutes.search
const loginPathName = germanRoutes.login
const lostPasswordPathName = germanRoutes.lostPassword
const lostPasswordUpdatePathName = germanRoutes.lostPasswordUpdate
const signupPathName = germanRoutes.signup
const myProfilePathName = germanRoutes.myProfile
const editProfilePathName = germanRoutes.editProfile
const emailConfirmationPathName = germanRoutes.emailConfirmation
const whySignUpPathName = germanRoutes.whySignUp
const widgetsPathName = germanRoutes.widgets
const videocenterPathName = germanRoutes.videocenter
const pressPathName = germanRoutes.press
const tagsPathName = germanRoutes.tags
const fanshopsPathName = germanRoutes.fanshops
const ticketshopsPathName = germanRoutes.ticketshops
const infoPathName = germanRoutes.info
const memberClubsPathName = germanRoutes.memberClubs
const parentClubsPathName = germanRoutes.parentClubs
const presstextPathName = germanRoutes.presstext
const clubFinderPathName = germanRoutes.map

export const checkId = (url: string, obj: { id: string | null }): string =>
  obj.id ? url : nonValidUrl

export const rootPath = (): string => '/'

export const clubFinderPath = (): string => `/${clubFinderPathName}`

export const daySchedulePath = (): string => `/${daySchedulePathName}`
export const scheduleSearchPath = (): string => `/${scheduleSearchPathName}`

export const leaguesPath = (): string => `/${leaguesPathName}`

export const livePath = (): string => 'https://live.handball.net'

export const tournamentsPath = (): string => `/${tournamentsPathName}`

const getTournamentBasePath = (tournament: Tournament) =>
  tournament.tournamentType === 'Competition'
    ? tournamentsPathName
    : leaguesPathName

export const tournamentSeasonTablePath = (
  tournament: Tournament,
  season: string
): string => tournamentTablePath(tournament) + `?season=${season}`

export const tournamentWithFiltersTablePath = (
  tournament: Tournament,
  filters: Record<string, string | null | undefined>
): string => {
  const params = new URLSearchParams(getValidFilters(filters))
  return tournamentTablePath(tournament) + `?${params}`
}

export const tournamentTablePath = (tournament: Tournament): string =>
  checkId(
    `/${getTournamentBasePath(tournament)}/${tournament.id}/${tablePathName}`,
    tournament
  )

export const tournamentSeasonStatisticsPath = (
  tournament: Tournament,
  season: string
): string => tournamentStatisticsPath(tournament) + `?season=${season}`

export const tournamentWithFiltersStatisticsPath = (
  tournament: Tournament,
  filters: Record<string, string | null | undefined>
): string => {
  const params = new URLSearchParams(getValidFilters(filters))
  return tournamentStatisticsPath(tournament) + `?${params}`
}

export const tournamentStatisticsPath = (tournament: Tournament): string =>
  checkId(
    `/${getTournamentBasePath(tournament)}/${
      tournament.id
    }/${statisticsPathName}`,
    tournament
  )

export const tournamentSeasonSchedulePath = (
  tournament: Tournament,
  season: string
): string => tournamentSchedulePath(tournament) + `?season=${season}`

export const tournamentWithFiltersFiltersSchedulePath = (
  tournament: Tournament,
  filters: Record<string, string | null | undefined>
): string => {
  const params = new URLSearchParams(getValidFilters(filters))
  return tournamentSchedulePath(tournament) + `?${params}`
}

export const tournamentSchedulePath = (tournament: Tournament): string =>
  checkId(
    `/${getTournamentBasePath(tournament)}/${
      tournament.id
    }/${schedulePathName}`,
    tournament
  )

export const tournamentSchedulePathById = (tournament: string): string =>
  `/${tournamentsPathName}/${tournament}/${schedulePathName}`

export const leagueSchedulePathById = (league: string): string =>
  `/${leaguesPathName}/${league}/${schedulePathName}`

export const tournamentSeasonAllGamesPath = (
  tournament: Tournament,
  season: string
): string => tournamentAllGamesPath(tournament) + `?season=${season}`

export const tournamentWithFiltersAllGamesPath = (
  tournament: Tournament,
  filters: Record<string, string | null | undefined>
): string => {
  const params = new URLSearchParams(getValidFilters(filters))
  return tournamentAllGamesPath(tournament) + `?${params}`
}

export const tournamentAllGamesPath = (tournament: Tournament): string =>
  checkId(
    `/${getTournamentBasePath(tournament)}/${
      tournament.id
    }/${allGamesPathName}`,
    tournament
  )

export const tournamentLiveSteamPath = (tournament: Tournament): string =>
  `${livePath()}`

export const roundPath = (tournament: Tournament, round: Round): string =>
  checkId(
    `/${getTournamentBasePath(tournament)}/${
      tournament.id
    }/${schedulePathName}?round=${round.id}`,
    tournament
  )

export const gamePath = (game: Game): string => {
  const tournamentId = game.tournament.id
  const roundId = game.round.id

  return `/${getTournamentBasePath(
    game.tournament
  )}/${tournamentId}/${schedulePathName}/${roundsPathName}/${roundId}/${gamesPathName}/${
    game.id
  }`
}

const calendarBasePath = (type: 'apple' | 'google' | 'other'): string => {
  const baseUrl = apiBaseUrl.replace('https://', '').replace('www.', '')
  switch (type) {
    case 'apple':
      return `webcal://${baseUrl}/sportdata/1/calendar`
    case 'google':
      return `https://calendar.google.com/calendar/u/0/r?cid=webcal://${baseUrl}/sportdata/1/calendar`
    case 'other':
      return `webcal://${baseUrl}/sportdata/1/calendar`
  }
}

export const gameCalenderPath = (
  id: string,
  type: 'apple' | 'google' | 'other'
): string => {
  return `${calendarBasePath(type)}/game/${id}.ics`
}

export const tournamentCalenderPath = (
  id: string,
  type: 'apple' | 'google' | 'other',
  searchParams: { [key: string]: string | string[] | undefined } = {}
): string => {
  const queryParams = queryString.stringify(searchParams)
  return `${calendarBasePath(type)}/tournament/${id}.ics?${queryParams}`
}

export const teamCalenderPath = (
  id: string,
  type: 'apple' | 'google' | 'other',
  searchParams: { [key: string]: string | string[] | undefined } = {}
): string => {
  const queryParams = queryString.stringify(searchParams)
  return `${calendarBasePath(type)}/team/${id}.ics?${queryParams}`
}

export const gameTickerPath = (game: Game, tickerId: string): string => {
  return extendWithTickerPath(gamePath(game), tickerId)
}

const extendWithTickerPath = (base: string, tickerId: string): string => {
  return `${base}/${livePathName}/${tickerId}`
}

export const gamePresstextPath = (game: Game): string => {
  return `${gamePath(game)}/${presstextPathName}#spielbericht`
}

// Team
export const teamPath = (team: Team | string): string =>
  typeof team === 'string'
    ? `/${teamsPathName}/${team}/${schedulePathName}`
    : checkId(`/${teamsPathName}/${team.id}/${schedulePathName}`, team)

export const teamWithFiltersPath = (
  team: Team,
  filters: Record<string, string | null | undefined>
): string => {
  const params = new URLSearchParams(getValidFilters(filters))
  return checkId(
    `/${teamsPathName}/${team.id}/${schedulePathName}?${params}`,
    team
  )
}

export const teamLeagueSchedulePath = (team: Team): string =>
  checkId(`/${teamsPathName}/${team.id}/${teamLeagueSchedulePathName}`, team)

export const teamLeagueithFiltersSchedulePath = (
  team: Team,
  filters: Record<string, string | null | undefined>
): string => {
  const params = new URLSearchParams(getValidFilters(filters))
  return checkId(
    `/${teamsPathName}/${team.id}/${teamLeagueSchedulePathName}?${params}`,
    team
  )
}

export const teamTablePath = (team: Team): string =>
  checkId(`/${teamsPathName}/${team.id}/${tablePathName}`, team)

export const teamWithFiltersTablePath = (
  team: Team,
  filters: Record<string, string | null | undefined>
): string => {
  const params = new URLSearchParams(getValidFilters(filters))
  return checkId(
    `/${teamsPathName}/${team.id}/${tablePathName}?${params}`,
    team
  )
}

export const teamSquadPath = (team: Team): string =>
  checkId(`/${teamsPathName}/${team.id}/${squadPathName}`, team)

export const imagePath = (image: string, variant?: string): string => {
  if (variant) {
    return `/img/${variant}/${image}`
  } else {
    return `/img/${image}`
  }
}

export const newsPath = (news?: News): string => {
  if (news) {
    return `/${newsPathName}/${news.slug}`
  } else {
    return `/${newsPathName}`
  }
}

export const newsUrl = (news?: News): string => siteBaseUrl + newsPath(news)

export const newsTagsPath = (tags: string): string =>
  `/${newsPathName}/${tagsPathName}/${tags}`

export const videocenterPath = (news?: News): string => {
  if (news) {
    return `/${videocenterPathName}/${news.slug}`
  } else {
    return `/${videocenterPathName}`
  }
}

export const videocenterUrl = (news?: News): string =>
  siteBaseUrl + videocenterPath(news)

export const error404Path = (): string => '/404'
export const error500Path = (): string => '/500'

// Clubs
export const clubsPath = (): string => `/${clubsPathName}`
export const clubPath = (club: Club | string, addTeam = false): string =>
  typeof club === 'string'
    ? `/${clubsPathName}/${club}${addTeam ? '?addTeam=true' : ''}`
    : `/${clubsPathName}/${club.id}${addTeam ? '?addTeam=true' : ''}`
export const clubSchedulePath = (club: Club): string =>
  `/${clubsPathName}/${club.id}/${schedulePathName}`
export const clubInfoPath = (club: Club | string): string =>
  typeof club === 'string'
    ? `/${clubsPathName}/${club}/${infoPathName}`
    : `/${clubsPathName}/${club.id}/${infoPathName}`
export const clubMemberClubsPath = (club: Club): string =>
  `/${clubsPathName}/${club.id}/${memberClubsPathName}`
export const clubParentClubsPath = (club: Club): string =>
  `/${clubsPathName}/${club.id}/${parentClubsPathName}`

// Organizations
export const organizationsPath = (): string => `/${organizationsPathName}`
export const organizationPath = (org: Organization): string =>
  `/${organizationsPathName}/${org.id}`
export const organizationTournamentsPath = (org: Organization): string =>
  `/${organizationsPathName}/${org.id}/${tournamentsPathName}`
export const organizationClubsPath = (org: Organization): string =>
  `/${organizationsPathName}/${org.id}/${clubsPathName}`

// Fields
export const fieldsPath = (): string => `/${fieldsPathName}`
export const fieldPath = (field: Field | string): string =>
  typeof field === 'string'
    ? `/${fieldsPathName}/${field}`
    : checkId(`/${fieldsPathName}/${field.id}`, field)

// Static
export const soTechCup = (): string =>
  '/wettbewerbe/sportradar.dhbdata.16466/spielplan'
export const nutritionBlogPath = (): string => `/${nutritionBlogPathName}`
export const healthBlogPath = (): string => `/${healthBlogPathName}`
export const imprintPath = (): string => `/${imprintPathName}`
export const contactPath = (): string => `/${contactPathName}`
export const privacyPolicyUrl = (): string => 'https://www.dhb.de/datenschutz'
export const termsOfUsePath = (): string => `/${termsOfUsePathname}`
export const faqPath = (): string => `/${faqPathName}`
export const newsletterPath = (): string => `/${newsletterPathName}`
export const pressPath = (): string => `/${pressPathName}`
export const fanshopsPath = (): string => `/${fanshopsPathName}`
export const sammelalbumPath = (): string =>
  '/news/danke-handball-net-allstars-euro-2024-deutschland'
export const adventCalendarPath = (): string => '/news/tags/adventskalender'
export const ticketshopsPath = (): string => `/${ticketshopsPathName}`
export const amateurGoalOfTheMonthPath = (): string => '/amateurtor'

export const searchPath = (params?: Record<string, string>): string =>
  `/${searchPathName}${params ? `?${new URLSearchParams(params)}` : ''}`

export const searchClubsPath = (params?: Record<string, string>): string =>
  `/${searchPathName}/${clubsPathName}${
    params ? `?${new URLSearchParams(params)}` : ''
  }`

export const searchFieldsPath = (params?: Record<string, string>): string =>
  `/${searchPathName}/${fieldsPathName}${
    params ? `?${new URLSearchParams(params)}` : ''
  }`

export const searchTeamsPath = (params?: Record<string, string>): string =>
  `/${searchPathName}/${teamsPathName}${
    params ? `?${new URLSearchParams(params)}` : ''
  }`

export const searchTournamentsPath = (
  params?: Record<string, string>
): string =>
  `/${searchPathName}/${tournamentsPathName}${
    params ? `?${new URLSearchParams(params)}` : ''
  }`

// User
export const loginPath = (
  p: { loggedOut?: string; backTo?: string } = {}
): string => {
  const params = new URLSearchParams(p).toString()
  return `/${loginPathName}${params ? `?${params}` : ''}`
}
export const lostPasswordPath = (): string => `/${lostPasswordPathName}`
export const lostPasswordUpdatePath = (): string =>
  `/${lostPasswordUpdatePathName}`
export const signupPath = (): string => `/${signupPathName}`
export const emailConfirmationPath = (): string =>
  `/${emailConfirmationPathName}`
export const whySignUpPath = (): string => `/${whySignUpPathName}`
export const myProfilePath = (): string => `/${myProfilePathName}`
export const editProfilePath = (): string => `/${editProfilePathName}`

// Widgets
export const widgetsHomePath = (): string => `/${widgetsPathName}`
export const widgetTeamTablePath = (team: Team): string =>
  team.id
    ? `/${widgetsPathName}/${teamsPathName}/${team.id}/${tablePathName}`
    : nonValidUrl
export const widgetTeamSchedulePath = (team: Team): string =>
  team.id
    ? `/${widgetsPathName}/${teamsPathName}/${team.id}/${schedulePathName}`
    : nonValidUrl
export const widgetClubSchedulePath = (club: Club): string =>
  club.id
    ? `/${widgetsPathName}/${clubsPathName}/${club.id}/${schedulePathName}`
    : nonValidUrl

export const widgetTournamentSchedulePath = (tournament: Tournament): string =>
  tournament.id
    ? `/${widgetsPathName}/${getTournamentBasePath(tournament)}/${
        tournament.id
      }/${schedulePathName}`
    : nonValidUrl
export const widgetTournamentTablePath = (tournament: Tournament): string =>
  tournament.id
    ? `/${widgetsPathName}/${getTournamentBasePath(tournament)}/${
        tournament.id
      }/${tablePathName}`
    : nonValidUrl

export const widgetOrganizationTeamsPath = (
  organization: Organization
): string =>
  organization.id
    ? `/${widgetsPathName}/${organizationsPathName}/${organization.id}/${teamsPathName}`
    : nonValidUrl

// External
export const fanshopPath = (): string => 'https://shop.dhb.de'
export const HandballNetPath = (): string =>
  'https://tickets.eurohandball.com/de/men2024'
export const ticketshopPath = (): string =>
  'https://www.eventimsports.de/ols/dhb/'
export const refereePortalPath = (): string =>
  'https://www.dhb-schiedsrichterportal.de/'
export const trainerPortalPath = (): string =>
  'https://www.dhb-trainercenter.de/'
// export const tippspielPortalPath = (): string =>
//   'https://tippspiel.handball.net/#/home'
export const heretoplayUrl = (): string => 'https://heretoplay.com/'
export const wmTurnierPassUrl = (): string =>
  'https://sportdeutschland.tv/l/handball-maenner-wm-2023-turnier-pass'
export const googlePlayPath = (): string =>
  'https://play.google.com/store/apps/details?id=net.handball.app'
export const appStorePath = (): string =>
  'https://apps.apple.com/de/app/handball-net/id1582777457'
export const managerGamePath = (): string => 'https://www.liquimoly-hbl.de'
export const SpielerPlusPath = (): string => 'https://www.spielerplus.de'
export const SpielerPlusPathProfile = (): string =>
  'https://www.spielerplus.de/?utm_source=handballnet&utm_medium=Profil&utm_campaign=Handball-Kooperation'
export const SpielerPlusAdPath = (): string =>
  'https://www.spielerplus.de/?utm_source=handballnet&utm_medium=sidebar&utm_campaign=Handball-Kooperation'
export const PartnerAtlasPath = (): string => 'https://www.atlasschuhe.de/'
export const PartnerAutoheroPath = (): string =>
  'https://www.autohero.com/de/?utm_source=DHB&utm_medium=Brand&utm_campaign=DHB_Banner_Website&utm_term=1601&utm_content=Handball_net_Powered_by_Landingpage&MID=DE_DAB_8000_1_0_0_0_0_6'
export const PartnerAutoheroBannerSmPath = (): string =>
  'https://www.autohero.com/de/?utm_source=DHB&utm_medium=Brand&utm_campaign=DHB_Banner_Website&utm_term=1601&utm_content=Handball_net_Powered_by_Landingpage&MID=DE_DAB_8000_1_0_0_0_0_6'
export const PartnerAutoheroBannerPath = (): string =>
  'https://www.autohero.com/de/?utm_source=DHB&utm_medium=Brand&utm_campaign=DHB_Banner_Website&utm_term=1601&utm_content=Handball_net_Powered_by_Landingpage&MID=DE_DAB_8000_1_0_0_0_0_6'
export const PartnerAutoheroMyHandballPath = (): string =>
  'https://www.autohero.com/de/?utm_source=DHB&utm_medium=Brand&utm_campaign=DHB_Banner_Website&utm_term=1601&utm_content=Handball_net_Mein_Handball&MID=DE_DAB_8000_1_0_0_0_0_7'
export const PartnerLIDLPath = (): string =>
  'https://www.lidl-kochen.de/fitness?utm_source=handball.net&utm_medium=banner&utm_campaign=Ernaehrungsblog'
// 'https://www.lidl-kochen.de/?utm_source=handball.net&utm_medium=banner&utm_campaign=Ernaehrungsblog'
export const PartnerIhreApotheken = (): string =>
  'https://ihreapotheken.de/themenuebersicht?utm_source=handballnet&utm_medium=banner&utm_campaign=awareness&utm_term=ihreapotheken&utm_content=contentblog'
export const HartingPath = (): string =>
  'https://www.harting.com/DE/de?utm_source=handball.net&utm_medium=logo&utm_campaign=news_nationalmannschaften'
export const ihfU21 = (): string =>
  'https://www.ihf.info/competitions/junior-men/310/24th-ihf-mens-junior-u21-world-championship/121823'
